<template>
  <div class="questionAnswerData">
    <s-header :hasBack="true" />
    <n-bar :userType="'teacher'" :activeItemName="'index'" />
    <support />

    <div class="pageContent">
      <div class="in">
        <h2 style="text-align: center">题目答案分布</h2>
        <table class="answer" v-if="tableData.length">
          <thead>
            <tr>
              <td v-for="item in tableData">{{ item.answer }}</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td v-for="item in tableData">{{ item.count }}</td>
            </tr>
          </tbody>
        </table>
        <div v-if="!tableData.length">
          <van-empty
            :image="require('@/assets/images/kong.png')"
            image-size="80"
            description="暂无数据"
          />
        </div>

        <div id="chart" ref="answerChart"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { apiGetQuestionAnswer } from "@/service/teacher1.4.js";
import * as echarts from "echarts/core";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import { PieChart } from "echarts/charts";
import { LabelLayout } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import sHeader from "@/components/SimpleHeader";
import nBar from "@/components/NavBar";
import support from "@/components/Support";

const route = useRoute();

echarts.use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  PieChart,
  CanvasRenderer,
  LabelLayout,
]);

let answerChart = ref(null);
let tableData = ref([]);
onMounted(async () => {
  var chartDom = answerChart.value;
  var myChart = echarts.init(chartDom);
  var option;
  let data = ref([]);
  let res = await apiGetQuestionAnswer({ qa_id: route.query.id });
  if (res) {
    data.value = res.data.map((item) => {
      return {
        value: item.count,
        name: item.answer,
      };
    });
    tableData.value = res.data;
  }

  option = {
    title: {
      text: "",
      left: "center",
    },
    tooltip: {
      trigger: "item",
    },
    legend: {
      y: "bottom",
      x: "center",
    },
    series: [
      {
        name: "单位（人）",
        type: "pie",
        radius: "50%",
        data: data.value,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };

  option && myChart.setOption(option);
});
</script>

<style lang="less" scoped>
@import "@/common/style/pageContent.less";
@import "@/common/style/adapting.less";
.answer {
  background-color: rgb(249, 249, 249);
  margin: auto;
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  thead {
    background-color: #18a4e0;
  }
  td {
    border: 0.5px solid #eee;
    height: 20px;
    line-height: 20px;
    font-size: 11px;
  }
}

#chart {
  margin-top: 50px;
  height: 300px;
}
</style>
